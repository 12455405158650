import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  date: {
    margin: 24,
    marginBottom: 12,
    padding: 12,
    [theme.breakpoints.down('xs')]: {
      margin: 16,
      marginBottom: 8,
      padding: 8,
    },
  },
  stats: {
    margin: 24,
    marginBottom: 12,
    padding: 12,
    '& .chart-js-container': {
      height: 'calc(100vh - 200px)',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 16,
      marginBottom: 8,
      padding: 8,
    },
  },
  start: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  end: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  tool: {
    margin: 24,
    marginTop: 12,
    marginBottom: 16,
    padding: 12,
    [theme.breakpoints.down('xs')]: {
      margin: 16,
      marginTop: 8,
      marginBottom: 12,
      padding: 8,
    },
  },
  select: {
    justifyContent: 'center',
    '& .MuiFormControl-root.MuiTextField-root, .MuiButtonBase-root.MuiButton-root': {
      width: 470,
      maxWidth: '100%',
    },
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  button: {
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export default useStyles;
