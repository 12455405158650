const leadingZero = (integer) => ((`0${integer}`).slice(-2));

const AdvancedToolsStatsByDay = (data) => (
  data && data.map((y) => ({
    year: y.year,
    months: y.months.map((m) => ({
      month: m.name,
      number: leadingZero(m.number),
      days: Object.keys(m.useByDay).map((day) => ({
        day,
        use: m.useByDay[day],
      })),
    })),
  }))
    .map((y) => y.months
      .map((m) => m.days && m.days
        .map((d) => ({
          year: y.year,
          month: m.number,
          day: d.day,
          date: `${y.year}-${m.number}-${leadingZero(d.day)}`,
          use: d.use,
        }))))
    .reduce((y, m) => [...y, ...m])
    .reduce((m, d) => [...m, ...d])
);

const AdvancedToolsChartData = (input) => {
  const { stats, tools } = input;
  const opacity = '90'; // hex value: 00...ff
  const colors = [
    '#16a085',
    '#2980b9',
    '#8e44ad',
    '#c0392b',
    '#f37b12',
    '#f1c40f',
    '#7f8c8d',
    '#34495e',
  ];

  const buildLabelDataset = (tool, label) => {
    const stat = stats.find((act) => act.date === label);

    if (!stat) return 0;

    const use = stat && stat.use && stat.use.find((u) => u.uuid === tool.uuid);
    if (!use) return 0;

    return use.count;
  };

  const labels = stats && stats.map((d) => d.date);
  const datasets = tools && tools.map((tool, key) => ({
    label: tool.name,
    data: labels && labels.map((day) => buildLabelDataset(tool, day)),
    backgroundColor: `${colors[(key % colors.length)]}${opacity}`,
  }));

  return { labels, datasets };
};

export { AdvancedToolsStatsByDay, AdvancedToolsChartData };
