import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import { useKeycloak } from 'react-keycloak';

import { Grid, Paper } from '@material-ui/core';
import { Assessment, Close, DateRange } from '@material-ui/icons';
import { useQuery } from '@apollo/react-hooks';

import useStyles from '../styled/AdvancedToolsStatsStyles';
import { GET_HOSPITAL_ADVANCED_TOOLS } from '../../../queries/AdvancedTools/AdvancedTools';
import { canI } from '../../../casl/Can';
import { AlertUI, AlertWrapperUI } from '../../../componentsUI/Alert';
import { ArticleContainerUI } from '../../Common/styled/ArticleContainerUI';
import { Navbar } from '../../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../../componentsUI/SectionBar';
import { DateTimeFieldUI } from '../../../componentsUI/DateTimeField';
import { Form } from '../../Common/styled/Form';
import { TextFieldSelectUI } from '../../../componentsUI/TextFieldSelect';
import Loading from '../../Common/Loading';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import { AdvancedToolsStatsChart } from './AdvancedToolsStatsChart';
import { AdvancedToolsStatsByDay, AdvancedToolsChartData } from './AdvancedToolsStatsParser';
import { ButtonUI } from '../../../componentsUI/Button';

const getDefaultStartDate = () => {
  const d = new Date();
  return new Date(d.setMonth(d.getMonth() - 1));
};

export const AdvancedToolsStats = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const [keycloak] = useKeycloak();
  const today = new Date();

  const [display, setDisplay] = useState('form');
  const [statsError, setStatsError] = useState(null);
  const [statsLoading, setStatsLoading] = useState(null);
  const [statsQueryData, setStatsQueryData] = useState(null);
  const [advancedToolUuid, setAdvancedToolUuid] = useState(null);

  const [dateRange, setDateRange] = useState({
    start: getDefaultStartDate(),
    end: today,
  });

  const { error, loading, data } = hospital && useQuery(GET_HOSPITAL_ADVANCED_TOOLS, { variables: { uuid: hospital.uuid } });

  const getDateTimestamp = (date) => parseInt(date.getTime() / 1000, 10);

  const requestAdvancedTool = () => {
    setStatsLoading(true);
    setStatsError(false);
    setDisplay('stats');

    const startTime = getDateTimestamp(dateRange.start);
    const endTime = getDateTimestamp(dateRange.end);
    const advancedToolQuery = advancedToolUuid ? `&advancedToolUuid=${advancedToolUuid}` : '';
    const hospitalQuery = hospital && hospital.uuid ? `&hospitalUuid=${hospital.uuid}` : '';
    const statsQuery = `start=${startTime}&end=${endTime}${advancedToolQuery}${hospitalQuery}`;

    fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/advanced-tool/stats?${statsQuery}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          setStatsError(`${response.status} - ${response.statusText}`);
        }
        response.json().then((json) => {
          setStatsQueryData(json);
        });
        setStatsLoading(false);
      })
      .catch((e) => {
        setStatsLoading(false);
        setStatsError(e);
      });
  };

  const goBack = () => navigate('/dashboard');

  const advancedTools = data && data.hospital && data.hospital.availableAdvancedTools;

  const advancedToolSelectList = advancedTools && advancedTools.length && [
    { id: 0, value: '', label: 'select.advanced.tool' },
    ...advancedTools.map((tool, key) => ({ id: key, value: tool.uuid, label: tool.name }))];

  const selectedTools = advancedToolUuid
    ? [advancedTools.find((tool) => tool.uuid === advancedToolUuid)]
    : advancedTools;

  let statsData;

  try {
    const statsByDay = AdvancedToolsStatsByDay(statsQueryData);

    statsData = AdvancedToolsChartData({
      stats: statsByDay,
      tools: selectedTools,
    });
  } catch (e) {
    console.warn(e);
  }

  const statsDisabled = !advancedToolSelectList || (display === 'stats' && !statsError);

  const buttons = [
    { name: 'select.chart.options', icon: DateRange, handleClick: () => setDisplay('form'), disabled: display === 'form' },
    { name: 'display.stats', icon: Assessment, handleClick: requestAdvancedTool, disabled: statsDisabled },
    { name: 'divider2', type: 'divider' },
    { name: 'return', icon: Close, handleClick: goBack, disabled: false },
  ];

  const updateStartDate = (val) => {
    setDateRange({
      start: val,
      end: dateRange.end,
    });
  };
  const updateEndDate = (val) => {
    setDateRange({
      start: dateRange.start,
      end: val,
    });
  };
  const updateAdvancedTool = (uuid) => {
    setAdvancedToolUuid(uuid);
  };

  const minDate = new Date('2020-01-01');
  const initialDates = {
    start: dateRange.start,
    end: dateRange.end,
  };

  const allowed = canI('delete', hospital);

  const ErrorComponent = ({ message }) => (
    <AlertWrapperUI>
      <AlertUI severity="error" title="Error">{t(message)}</AlertUI>
    </AlertWrapperUI>
  );

  const ForbiddenAccess = () => (
    <AlertWrapperUI>
      <AlertUI severity="warning" title={t('access.forbidden')}>{t('you.are.not.allowed.to.perform.this.action')}</AlertUI>
    </AlertWrapperUI>
  );

  const NoAdvancedTools = () => (
    <AlertWrapperUI>
      <AlertUI severity="warning" title={t('option.not.available')}>{t('there.is.no.advanced.tool.enabled.in.this.hospital')}</AlertUI>
    </AlertWrapperUI>
  );

  return (
    <ArticleContainerUI>
      <Navbar>
        <SectionBar title="advanced.tools.stats" items={buttons} />
      </Navbar>
      {!hospital && <Loading />}
      {!loading && error && <ErrorComponent message={networkErrorParse(error)} />}
      {!allowed && hospital.owner && <ForbiddenAccess />}
      {!!hospital && !error && allowed && display === 'form' && (
        <Formik onSubmit={requestAdvancedTool} initialValues={initialDates}>
          {(props) => (
            <Form autoComplete="off" id="ai-stats">
              <Paper elevation={2} className={styles.date}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} container className={styles.start}>
                    <DateTimeFieldUI
                      variant="outlined"
                      name="start"
                      minDate={minDate}
                      maxDate={dateRange.end}
                      iconPosition="right"
                      label={t('start.date.info')}
                      props={props}
                      classes={styles}
                      handleChange={updateStartDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} container className={styles.end}>
                    <DateTimeFieldUI
                      name="end"
                      minDate={dateRange.start}
                      maxDate={today}
                      iconPosition="right"
                      label={t('end.date.info')}
                      props={props}
                      classes={styles}
                      handleChange={updateEndDate}
                    />
                  </Grid>
                </Grid>
              </Paper>
              {loading ? <Loading /> : (
                <>
                  {!advancedToolSelectList && <NoAdvancedTools />}
                  {!!advancedToolSelectList && (
                    <>
                      <Paper elevation={2} className={styles.tool}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} container className={styles.select}>
                            <TextFieldSelectUI
                              classes={styles}
                              multiple
                              name="advancedTool"
                              label={t('advanced.tool')}
                              props={props}
                              options={advancedToolSelectList}
                              onClick={updateAdvancedTool}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper elevation={2} className={styles.tool}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} container className={styles.select}>
                            <ButtonUI
                              variant="contained"
                              color="primary"
                              disabled={false}
                              type="submit"
                            >
                              {t('display.stats')}
                            </ButtonUI>
                          </Grid>
                        </Grid>
                      </Paper>
                    </>
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
      {statsLoading && <Loading />}
      {statsError && <ErrorComponent message={statsError} />}
      {!!hospital && !statsLoading && !statsError && allowed && display === 'stats' && (
        <Paper elevation={2} className={styles.stats}>
          {statsData && <AdvancedToolsStatsChart data={statsData} />}
        </Paper>
      )}
    </ArticleContainerUI>
  );
};
