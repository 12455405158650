import React from 'react';

import Layout from '../../components/MenuLayout';
import { AdvancedToolsStats } from '../../components/AdvancedTools/stats/AdvancedToolsStats';

export default () => (
  <Layout>
    <AdvancedToolsStats />
  </Layout>
);
